import routeGuard from "$lib/routes/route-guard";
import { client } from "src/lib/apollo/client-provider";
import { loading } from "src/stores/loading";
import { setClient } from "svelte-apollo";
import "../app.css";
import type { LayoutLoad } from "./$types";

export const ssr = true;
export const csr = true;
export const prerender = "auto";

export const load: LayoutLoad = async (event) => {
	// automatically triggers silent auth
	loading.set(true);
	setClient(client);

	await routeGuard(event.route.id);
	return;
};
