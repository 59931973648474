import { browser } from "$app/environment";
import { redirect } from "@sveltejs/kit";
import userStore from "src/stores/user";
import publicRoutes from "./public-routes.json";

export async function routeGuard(routeId: string): Promise<void> {
	const authed = await userStore.isAuthenticated();

	if (!browser) {
		// requires page auth
		return;
	} else if (authed) {
		if (userStore.getSelectedProjectId()) return;
		else if (routeId != "/teams") redirect(302, "/teams");

		return;
	} else if (publicRoutes.includes(routeId)) {
		return;
	} else {
		redirect(302, "/");
	}
}

export default routeGuard;
