<script lang="ts">
	import { afterNavigate } from "$app/navigation";
	import { InsertActionVisit } from "src/graphql-operations";
	import userStore from "src/stores/user";

	afterNavigate(async (nav) => {
		if (await userStore.isAuthenticated()) {
			InsertActionVisit({
				variables: {
					user_id: (await userStore.getAuth0User()).sub,
					team_id: userStore.getSelectedProjectId(),
					at_page_slug: nav.to.url.pathname,
					previous_page_slug: nav.from?.url?.pathname ?? undefined,
				},
			});
		}
	});
</script>

<div
	class="relative h-full min-h-screen bg-[#f4f5f5] text-gray-brand-500"
	data-sveltekit-preload-data="hover"
>
	<slot />
</div>
